import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: verdana;
    margin: 0px;
    padding: 0px;
    background-color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-track {
    background-color: #545454;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #a8a8a8;
    border-radius: 10px;
    border: 4px solid #545454;
  }
`;

export default GlobalStyles;
