import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const StyledLogoutSectionContainer = styled.section`
  width: 55px;
  height: 100%;
  margin: 0px;
  padding: 0px;
`;

const StyledLogoutButton = styled.button`
  width: 100%;
  height: 100%;
  border-width: 0px;
  background-color: transparent;
  outline: 0px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  transition: background-color 0.5s;
  &:hover {
    background-color: #9d0101;
  }
  svg {
    font-size: 20px;
    color: #ffffff;
    margin: 0px;
    padding: 0px;
  }
`;

const LogoutSection = ({ onClickLogout }) => {
  return (
    <StyledLogoutSectionContainer>
      <StyledLogoutButton type='button' onClick={onClickLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} />
      </StyledLogoutButton>
    </StyledLogoutSectionContainer>
  );
};

export default LogoutSection;
