import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ width }) => (width ? width : 'auto')};
  margin: 0px;
  padding: 0px;
`;

const Image = ({ source, alt, ...rest }) => {
  return <StyledImage src={source} alt={alt} {...rest} />;
};

export default Image;
