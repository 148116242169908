import React from 'react';
import styled from 'styled-components';

const StyledTableBody = styled.tbody`
  margin: 0px;
  padding: 0px;
`;

const TableBody = ({ children, ...rest }) => {
  return <StyledTableBody {...rest}>{children}</StyledTableBody>;
};

export default TableBody;
