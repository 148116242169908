import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  margin: 0px;
  padding: 0px 0px 5px 0px;
  color: #000000;
  text-transform: capitalize;
`;

const StyledStar = styled.span`
  color: red;
`;

const Label = ({ required = false, children, ...rest }) => {
  return (
    <StyledLabel {...rest}>
      {children}
      {required && <StyledStar>*</StyledStar>}
    </StyledLabel>
  );
};

export default Label;
