import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const StyledAccordinationContainer = styled.section`
  width: calc(100% - 2px);
  height: auto;
  margin: 0px 0px 20px;
  padding: 0px;
  border: 1px solid #545454;
  background-color: #ffffff;
  border-radius: 5px;
  &:last-child {
    margin: 0px;
  }
`;

const StyledAccordinationHeader = styled.section`
  width: calc(100% - 40px);
  height: auto;
  margin: 0px;
  padding: 20px;
  background-color: #545454;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  cursor: pointer;
  &.not-expanded {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  svg {
    font-size: 24px;
    position: absolute;
    top: 25%;
    right: 20px;
  }
`;

const StyledAccordinationBody = styled.section``;

const Accordination = ({ autoExpanded = false, header, children }) => {
  const [expanded, setExpanded] = useState(autoExpanded);

  const onClick = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <StyledAccordinationContainer>
      <StyledAccordinationHeader
        onClick={onClick}
        className={!expanded ? 'not-expanded' : ''}
      >
        {header}
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      </StyledAccordinationHeader>
      {expanded && (
        <StyledAccordinationBody>{children}</StyledAccordinationBody>
      )}
    </StyledAccordinationContainer>
  );
};

export default Accordination;
