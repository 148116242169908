import React, { useState } from 'react';
import styled from 'styled-components';

const StyledSwitchContainer = styled.section`
  width: 52px;
  height: 20px;
  margin: 0px;
  padding: 4px;
  background-color: red;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  font-size: 13px;
  color: #ffffff;
  transition: background-color 0.5s, color 0.5s;
  display: inline-block;
  &.active {
    background-color: forestgreen;
  }
`;

const StyledSwitchText = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 6px;
  left: 27px;
  transition: left 0.5s;
  &.active {
    left: 10px;
  }
`;

const StyledSwitchThumb = styled.section`
  width: 20px;
  height: 20px;
  margin: 0px;
  padding: 0px;
  border-radius: 100px;
  background-color: #ffffff;
  position: absolute;
  top: 4px;
  left: 4px;
  transition: left 0.5s, background-color 0.5s;
  &.active {
    left: 36px;
  }
`;

const Switch = ({ value, onChange, showSwitchLabels = true }) => {
  const [switchValue, setSwitchValue] = useState(value);

  const onClickSwitch = () => {
    setSwitchValue((switchValue) => !switchValue);
    onChange(!switchValue);
  };

  return (
    <StyledSwitchContainer
      className={switchValue ? `active` : ``}
      onClick={onClickSwitch}
    >
      {showSwitchLabels && (
        <StyledSwitchText className={switchValue ? `active` : ``}>
          {switchValue ? 'ON' : 'OFF'}
        </StyledSwitchText>
      )}
      <StyledSwitchThumb
        className={switchValue ? `active` : ``}
      ></StyledSwitchThumb>
    </StyledSwitchContainer>
  );
};

export default Switch;
