import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
  border-collapse: collapse;
  position: relative;
  border-radius: inherit;

  display: table;
`;

const Table = ({ children, ...rest }) => {
  return <StyledTable {...rest}>{children}</StyledTable>;
};

export default Table;
