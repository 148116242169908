import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

const StyledModalContainer = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  z-index: 9999;
`;

const StyledOverlay = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
  background-color: rgb(0 0 0 / 50%);
`;

const StyledModalBoxOuter = styled.section`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledModalBox = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  position: relative;
  @media (max-width: 600px) {
    width: calc(100% - 40px);
    img {
      display: block;
      margin: 0 auto;
    }
  }
`;

const StyledCloseButton = styled.section`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Modal = ({
  modalStatus = false,
  setModalStatus,
  closeOnOverlayClick = true,
  hideCloseButton,
  children,
}) => {
  const closeModal = () => {
    setModalStatus(false);
  };

  const onClickOverlay = () => {
    closeOnOverlayClick && closeModal();
  };

  return createPortal(
    modalStatus && (
      <StyledModalContainer>
        <StyledOverlay onClick={onClickOverlay}>
          <StyledModalBoxOuter>
            <StyledModalBox onClick={(e) => e.stopPropagation()}>
              {!hideCloseButton && (
                <StyledCloseButton onClick={closeModal}>X</StyledCloseButton>
              )}
              {children}
            </StyledModalBox>
          </StyledModalBoxOuter>
        </StyledOverlay>
      </StyledModalContainer>
    ),
    document.getElementById('modals')
  );
};

export default Modal;
