import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { StyledFiltersButton } from '../Styled';

const FiltersButton = ({ onClick, value }) => {
  return (
    <StyledFiltersButton onClick={onClick} className={value ? 'active' : ''}>
      <FontAwesomeIcon icon={faFilter} />
      Filters
    </StyledFiltersButton>
  );
};

export default FiltersButton;
