import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const StyledDropdownContainer = styled.section`
  display: block;
  width: calc(100% - 2px);
  height: auto;
  font-size: 14px;
  padding: 0px;
  outline-color: #cacaca;
  position: relative;
`;

const StyledSelectedOptionContainer = styled.section`
  display: block;
  width: calc(100% - 50px);
  height: auto;
  font-size: 14px;
  padding: 15px 40px 15px 10px;
  border: 1px solid #e5e5e5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: ${(props) =>
    props.expandOptions ? '0px' : '5px'};
  border-bottom-right-radius: ${(props) =>
    props.expandOptions ? '0px' : '5px'};
  cursor: ${({ isDisabled }) =>
    isDisabled === 'true' || isDisabled === true ? 'not-allowed;' : 'pointer;'};
  background-color: ${({ isDisabled }) =>
    isDisabled === 'true' || isDisabled === true ? '#fafafa;' : '#ffffff'};
  svg {
    position: absolute;
    top: 14px;
    right: 14px;
    color: #4a4a4a;
    font-size: 20px;
    cursor: pointer;
  }
`;

const StyledSelectedOption = styled.section`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  color: ${(props) => (props.isPlaceholder ? '#8f8f8f' : '#000000')};
`;

const StyledOptionsContainer = styled.section`
  position: absolute;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: -1px;
  z-index: 9999;
`;

const StyledSearchContainer = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
`;

const StyledSearchInput = styled.input`
  display: block;
  width: calc(100% - 32px);
  height: auto;
  font-size: 14px;
  padding: 15px 10px;
  margin: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline: 0px;
`;

const StyledOptionContainer = styled.section`
  width: 100%;
  height: auto;
  max-height: 300px;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const StyledOption = styled.section`
  width: 100%;
  height: auto;
  border-top: 1px solid #e5e5e5;
  margin: 0px;
  padding: 15px 20px;
  cursor: pointer;
  &:hover,
  &.active {
    background-color: #f3f3f3;
  }
  p {
    font-size: 14px;
    color: #000;
    margin: 0px;
    padding: 0px;
  }
`;

const Dropdown = ({
  value,
  onChange,
  options,
  placeholder = 'Please click to select',
  searchable = options && options.length > 10 ? true : false,
  multiSelect = false,
  disabled = false,
  ...rest
}) => {
  const dropdownRef = useRef(null);
  const [expandOptions, setExpandOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const toggleOptions = () => {
    !disabled && setExpandOptions((expandOptions) => !expandOptions);
  };

  const closeOptions = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setExpandOptions(false);
    }
  };

  const onChangeSearch = (e) => {
    const searchValue = e.target.value;
    setSearchText(searchValue);
    if (searchValue === '') {
      setSearchResults([]);
    } else {
      setSearchResults(
        options.filter(
          ({ title }) =>
            title
              .toString()
              .toLocaleLowerCase()
              .search(searchText.toString().toLocaleLowerCase()) !== -1
        )
      );
    }
  };

  const selectOption = (value) => {
    if (multiSelect) {
      const newValues = [...value];
      if ([...value].includes(value)) {
        onChange((value) => newValues.filter((item) => item !== value));
      } else {
        newValues.push(value);
        onChange(value);
      }
    } else {
      onChange(value);
      toggleOptions(false);
      setSearchText('');
      setSearchResults([]);
    }
  };

  useEffect(() => {
    document.addEventListener('click', closeOptions, true);
    return () => {
      document.removeEventListener('click', closeOptions, true);
    };
  }, []);

  const finalOptions =
    searchText !== '' && searchResults.length > 0 ? searchResults : options;

  return (
    <StyledDropdownContainer ref={dropdownRef}>
      <StyledSelectedOptionContainer
        expandOptions={expandOptions === true}
        onClick={toggleOptions}
        isDisabled={disabled}
        {...rest}
      >
        <StyledSelectedOption isPlaceholder={value?.length === 0}>
          {value?.length !== 0
            ? multiSelect
              ? `${
                  options.filter((item) => item.value === value[0])[0]?.title
                } ${value.length > 1 ? `+${value.length - 1}` : ``}`
              : options.filter((item) => item.value === value)[0]?.title
            : placeholder}
        </StyledSelectedOption>
        <FontAwesomeIcon icon={expandOptions ? faChevronUp : faChevronDown} />
      </StyledSelectedOptionContainer>
      {expandOptions && (
        <StyledOptionsContainer>
          {options.length > 0 && (
            <>
              {searchable && (
                <StyledSearchContainer>
                  <StyledSearchInput
                    type="search"
                    placeholder="Search..."
                    onChange={onChangeSearch}
                    value={searchText}
                    autoComplete="off"
                  />
                </StyledSearchContainer>
              )}
              <StyledOptionContainer>
                {finalOptions.map((item, index) => (
                  <StyledOption
                    className={
                      (multiSelect && value.includes(item.item)) ||
                      item.value === value
                        ? `active`
                        : ``
                    }
                    key={index}
                    onClick={() => selectOption(item.value)}
                  >
                    <p>{item.title}</p>
                  </StyledOption>
                ))}
                {finalOptions.length === 0 && <p>No options found</p>}
              </StyledOptionContainer>
            </>
          )}
          {options.length === 0 && <p>No options found</p>}
        </StyledOptionsContainer>
      )}
    </StyledDropdownContainer>
  );
};

export default Dropdown;
