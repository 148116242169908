import React from 'react';
import styled from 'styled-components';

const StyledFormContainer = styled.section`
  width: calc(100% - 42px);
  height: auto;
  margin: 0px auto;
  padding: 20px 20px 0px;
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: #ffffff;
`;

const FormContainer = ({ children, ...rest }) => {
  return <StyledFormContainer {...rest}>{children}</StyledFormContainer>;
};

export default FormContainer;
