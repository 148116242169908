import React from 'react';
import styled from 'styled-components';

const StyledTextarea = styled.textarea`
  display: block;
  width: calc(100% - 22px);
  height: auto;
  resize: none;
  min-height: 300px;
  max-height: 300px;
  font-size: 14px;
  padding: 15px 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  outline-color: #cacaca;
  background-color: #ffffff;
  &:disabled {
    cursor: not-allowed;
    background-color: #fafafa;
  }
`;

const Textarea = ({ placeholder, value, onChange, disabled, ...rest }) => {
  return (
    <StyledTextarea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  );
};

export default Textarea;
