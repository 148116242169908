import React from 'react';
import styled from 'styled-components';

const StyledLegend = styled.legend`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 5px 30px;
  font-size: 14px;
  background-color: #545454;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 5px;
`;

const Legend = ({ children, ...rest }) => {
  return <StyledLegend {...rest}>{children}</StyledLegend>;
};

export default Legend;
