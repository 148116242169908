import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogBox } from '../DialogBoxes';
import { Button } from '../FormElements';

const StyledIconButton = styled(Button)`
  padding: 0px;
  margin: 0px 5px;
  color: ${({ color }) => (color ? color : `red`)};
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: ${({ hoverColor }) => (hoverColor ? hoverColor : `#b30000`)};
  }
`;

const StyledTextButton = styled(Button)`
  font-size: '14px';
  font-weight: 'normal';
  color: '#ffffff';
  background-color: '#0071c2';
  border: '0px';
  border-radius: '50px';
  margin: '0px';
  padding: '12px 30px';
  letter-spacing: '0px';
  outline: '0px';
  cursor: 'pointer';
  width: 'auto';
  height: 'auto';
  transition: 'background-color 0.5s,color 0.5s';
`;

const CustomConfirmButton = ({
  showIcon = true,
  showTitle = false,
  icon,
  title = 'Delete',
  buttonTitle = '',
  onClick,
  color = '',
  hoverColor = '',
  buttonStyle,
  showTextButton = false,
  disabled,
  ...rest
}) => {
  const [confirmBoxStatus, setConfirmBoxStatus] = useState(false);

  const onClickIcon = () => {
    setConfirmBoxStatus(true);
  };

  const onProceed = () => {
    setConfirmBoxStatus(false);
    onClick();
  };

  const onCancel = () => {
    setConfirmBoxStatus(false);
  };

  return (
    <>
      {showTextButton ? (
        <StyledTextButton
          onClick={onClickIcon}
          color={color}
          hoverColor={hoverColor}
          style={buttonStyle}
          disabled={disabled}
        >
          {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
          {showTitle && ` ${buttonTitle ? buttonTitle : title}`}
        </StyledTextButton>
      ) : (
        <StyledIconButton
          onClick={onClickIcon}
          color={color}
          hoverColor={hoverColor}
          style={buttonStyle}
          disabled={disabled}
        >
          {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
          {showTitle && ` ${buttonTitle ? buttonTitle : title}`}
        </StyledIconButton>
      )}
      <ConfirmDialogBox
        status={confirmBoxStatus}
        onClickProceed={onProceed}
        onClickCancel={onCancel}
        icon={icon}
        title={title}
        color={color}
        hoverColor={hoverColor}
        {...rest}
      />
    </>
  );
};

export default CustomConfirmButton;
