import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import NumberInput from './NumberInput';

const StyledOTPInputContainer = styled.section`
  width: auto;
  height: auto;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => props.alignInputs};
  input {
    width: auto;
    height: auto;
    max-width: 50px;
    max-height: 50px;
    font-size: 22px;
    padding: 10px 3px;
    margin: 0px 5px;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #545454;
    outline: 0px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
    @media (max-width: 900px) {
      width: calc((100% / 6) - 16px);
    }
  }
`;

const OTPInput = ({
  values = [],
  onChange = () => {},
  length = 6,
  alignInputs = 'start',
  disabled = false,
}) => {
  const [inputStates, setInputStates] = useState([]);

  const onChangeInput = (value, e) => {
    const { name } = e.target;
    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {
      const inputName = parseInt(name);
      const newInputStates = [...inputStates];
      newInputStates[inputName - 1].value = value;
      newInputStates.map((item) => ({ ...item, focus: false }));

      if (isEmpty(value) && inputName - 1 === 0) {
        newInputStates[0].focus = true;
      } else if (!isEmpty(value) && inputName < length) {
        newInputStates[inputName].focus = true;
      } else if (inputName === length) {
        newInputStates[length - 1].focus = true;
      }

      setInputStates(newInputStates);
      onChange(newInputStates.map(({ value }) => value));
    }
  };

  const handleOnKeyDown = (e) => {
    const {
      key,
      keyCode,
      code,
      target: { name, value },
    } = e;

    if (
      (key === 'Backspace' || keyCode === 8 || code === 'Backspace') &&
      inputStates[parseInt(name) - 1].value === '' &&
      value === ''
    ) {
      const inputName = parseInt(name);
      const newInputStates = [...inputStates];
      newInputStates.map((item) => ({ ...item, focus: false }));

      if (inputName === 1) {
        newInputStates[0].focus = true;
      } else if (inputName - 1 < length) {
        newInputStates[inputName - 2].focus = true;
      }

      setInputStates(newInputStates);
    }
  };

  const generateInputs = useCallback(() => {
    const inputStates = [];

    for (let i = 1; i <= length; i++) {
      inputStates.push({
        key: i,
        name: i,
        id: Math.random() + i,
        value: !isEmpty(values[i - 1]) ? values[i - 1] : '',
        focus: false,
      });
    }

    setInputStates(inputStates);
  }, [length, values]);

  useEffect(() => {
    generateInputs();
  }, [generateInputs]);

  return (
    <StyledOTPInputContainer alignInputs={alignInputs}>
      {!isEmpty(inputStates) &&
        inputStates.map(({ key, name, id, value, focus }) => (
          <NumberInput
            key={key}
            name={name}
            id={id}
            value={value}
            onChange={onChangeInput}
            onKeyDown={handleOnKeyDown}
            maxLength={1}
            focus={focus}
            disabled={disabled}
            pattern={`[0-9]*`}
          />
        ))}
    </StyledOTPInputContainer>
  );
};

export default OTPInput;
