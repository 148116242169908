import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Button } from '.';

const StyledIconButton = styled(Button)`
  padding: 0px;
  margin: 0px 5px;
  color: forestgreen;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    color: #006200;
  }
`;

const CustomButton = ({
  showIcon = true,
  showTitle = false,
  icon,
  title = '',
  onClick,
  ...rest
}) => {
  return (
    <StyledIconButton onClick={onClick} {...rest}>
      {showIcon && <FontAwesomeIcon icon={icon ? icon : faTrashCan} />}
      {showTitle && ` ${title}`}
    </StyledIconButton>
  );
};

export default CustomButton;
