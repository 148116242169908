import React from 'react';
import styled from 'styled-components';

const StyledTableFooter = styled.tfoot`
  margin: 0px;
  padding: 0px;
  background-color: #545454;
  color: #ffffff;
  td {
    border-width: 0px;
    :first-child {
      background-color: #545454;
      border-bottom-left-radius: inherit;
    }
    :last-child {
      border-bottom-right-radius: inherit;
    }
  }
`;

const TableFooter = ({ children, ...rest }) => {
  return <StyledTableFooter {...rest}>{children}</StyledTableFooter>;
};

export default TableFooter;
