import React from 'react';
import styled from 'styled-components';

const StyledFieldset = styled.fieldset`
  display: block;
  width: ${(props) => (props.forLegend ? `calc(100% - 62px)` : `inherit`)};
  height: auto;
  margin: 0px 0px 20px 0px;
  padding: ${(props) => (props.forLegend ? `25px 30px` : `0px`)};
  border: ${(props) => (props.forLegend ? `1px solid #545454` : `0px`)};
  border-radius: 5px;
  position: relative;
  fieldset {
    width: 100%;
  }
`;

const Fieldset = ({ forLegend = false, children, ...rest }) => {
  return (
    <StyledFieldset forLegend={forLegend} {...rest}>
      {children}
    </StyledFieldset>
  );
};

export default Fieldset;
