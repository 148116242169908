import React from 'react';
import styled from 'styled-components';

const StyledTableHeader = styled.thead`
  margin: 0px;
  padding: 0px;
`;

const TableHeader = ({ children, ...rest }) => {
  return <StyledTableHeader {...rest}>{children}</StyledTableHeader>;
};

export default TableHeader;
