import React from 'react';
import styled from 'styled-components';

const StyledGrid = styled.section`
  width: 100%;
  height: auto;
  display: grid;
  margin: 0px;
  padding: 0px;
  ${({ columns }) =>
    columns && `grid-template-columns: ${columns}; grid-column-gap: 20px;`}
  ${({ rows }) => rows && `grid-template-rows: ${rows};grid-row-gap: 20px;`}
  @media (max-width: 600px) {
    grid-template-columns: 100%;
    word-break: break-all;
  }
`;

const Grid = ({ children, ...rest }) => {
  return <StyledGrid {...rest}>{children}</StyledGrid>;
};

export default Grid;
