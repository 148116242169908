import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledUpdateButton = styled(Button)`
  // width: 100px;
`;

const UpdateButton = ({ children, ...rest }) => {
  return <StyledUpdateButton {...rest}>{children}</StyledUpdateButton>;
};

export default UpdateButton;
