import React from 'react';
import styled from 'styled-components';

const StyledForm = styled.form``;

const Form = ({ children, ...rest }) => {
  return <StyledForm {...rest}>{children}</StyledForm>;
};

export default Form;
