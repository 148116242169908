import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const StyledLoadingSpinnerContainer = styled.section`
  width: calc(100% - 6px);
  height: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0%;
  color: #d1d1d1;
  border: 3px dashed #d1d1d1;
  svg {
    font-size: 50px;
    @media (max-width: 900px) {
      font-size: 30px;
    }
  }
  h2 {
    font-size: 22px;
    font-weight: normal;
    margin: 30px 0px 0px;
    text-transform: uppercase;
    letter-spacing: 5px;
    @media (max-width: 900px) {
      font-size: 14px;
      letter-spacing: 3px;
    }
  }
`;

const LoadingSpinner = ({ isLoading }) => {
  return (
    isLoading && (
      <StyledLoadingSpinnerContainer>
        <FontAwesomeIcon icon={faSpinner} spin bounce={true} />
        <h2>Loading</h2>
      </StyledLoadingSpinnerContainer>
    )
  );
};

export default LoadingSpinner;
