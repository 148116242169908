import React from 'react';
import styled from 'styled-components';

const StyledMarquee = styled.marquee`
  width: 100%;
  height: auto;
  margin: 5px auto;
  padding: 10px 0px;
  background-color: #ffbf00;
  font-size: 14px;
  font-weight: normal;
  color: #000000;
`;

const ScrollMessage = ({ scrollMessage }) => {
  return (
    scrollMessage && (
      <StyledMarquee direction='left'>{scrollMessage}</StyledMarquee>
    )
  );
};

export default ScrollMessage;
