import React from 'react';
import styled from 'styled-components';
import Button from './Button';

const StyledCreateButton = styled(Button)`
  // width: 100px;
`;

const CreateButton = ({ children, ...rest }) => {
  return <StyledCreateButton {...rest}>{children}</StyledCreateButton>;
};

export default CreateButton;
