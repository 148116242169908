import React from 'react';
import styled from 'styled-components';

const StyledTableHeading = styled.th`
  font-size: 14px;
  font-weight: normal;
  text-align: ${({ align }) => (align === 'center' ? `center` : `left`)};
  background-color: #545454;
  color: #ffffff;
  padding: 19px 15px;
  margin: 0px;
  top: 0px;
  left: 0px;
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  width: ${({ width }) => (width ? width : 'auto')};
`;

const TableHeading = ({ children, ...rest }) => {
  return <StyledTableHeading {...rest}>{children}</StyledTableHeading>;
};

export default TableHeading;
